const Works = [
  {
    id: 1,
    number: 1,
    color: "#E10495",
    bgColor: "rgba(225, 4, 149, 0.10)",
    title: "Deposit",
    desc: "Start your journey by depositing funds into your MyArbit account. It's a simple process, ensuring you have the capital ready to embark on your crypto investment strategy. As one of the leading investment platforms, MyArbit provides the tools and insights you need to make informed decisions and maximize your returns."
  },
  {
    id: 2,
    number: 2,
    color: "#fff",
    bgColor: "rgba(255, 255, 255, 0.10)",
    title: "Select Investment Pool",
    desc: "Select from a variety of investment pools that are shown on the dashboard. Because every pool has different opportunities and risk profiles, you can customize your investment plan to suit your preferences. Investigate arbitrage in cryptocurrency tactics, which capitalize on price variations between exchanges to make possible profits.",
  },
  {
    id: 3,
    number: 3,
    color: "#6690FF",
    bgColor: "rgba(102, 144, 255, 0.10)",
    title: "Stake (Invest)",
    desc: "Stake your desired amount in our investment pools, which are powered by one of the best crypto staking platforms available. By committing your funds for a specified period aligned with your chosen pool, you position yourself to capitalize on lucrative opportunities in crypto arbitrage, maximizing your investment potential.",
  },
  {
    id: 4,
    number: 4,
    color: "#06C289",
    bgColor: "rgba(6, 194, 137, 0.10)",
    title: "Withdraw",
    desc: "With the simplified approach offered by MyArbit, you can easily withdraw your profits at the end of the investment period. We make sure that the withdrawal process is easy for you so that you can take pleasure in the profits from your profitable exchange of crypto arbitrage.",
  },
];

export default Works;
